import angular from 'angular';
import uirouter from 'angular-ui-router';
import ngClipboard from 'ngclipboard';

import routing from './preview.routes';
import PreviewController, { NAME } from './preview.controller';

export default angular.module('app.preview', [uirouter, ngClipboard])
  .config(routing)
  .controller(NAME, [
    'session',
    'auth',
    '$scope',
    '$state',
    'facebook',
    PreviewController,
  ])
  .name;
