import angular from "angular";
import uirouter from "angular-ui-router";

import routing from "./userPalettes.routes";
import UserPalettesController from "./userPalettes.controller";
import session from "../../services/session.service";

export default angular.module("app.userPalettes", [uirouter, session])
  .config(routing)
  .controller("UserPalettesController", ["$mdDialog", "$scope", "session", "$state", UserPalettesController])
  .name;
