import { NAME, NAME_AS } from './preview.controller';
import template from './preview.html';

export default function routes($stateProvider) {
  $stateProvider
    .state('preview', {
      url: '/preview/{id}',
      template,
      controller: NAME,
      controllerAs: NAME_AS,
      params: {
        palette: null,
        id: null,
      },
    });
}

routes.$inject = ['$stateProvider'];
