import paletteService from './../../services/palette';

export default class PreviewController {
  constructor(session, auth, $scope, $state, facebook) {
    this._state = $state;
    this._facebook = facebook;
    this._scope = $scope;
    const { palette, id } = $state.params;
    if (!palette) { this._loadPreview(id); } else this._map(palette);
  }

  async _loadPreview(id) {
    this.isLoading = true;
    const palette = await paletteService.getPublicPalette(id);
    if (palette) this._map(palette);
    this.isLoading = false;
    this._scope.$apply();
  }

  _map(palette) {
    // const { preview: { name, signedUrl } } = palette;
    const { previewUrl } = palette;
    this.url = previewUrl;
    this.name = name;
    this.palette = palette;
  }

  onEditPaletteBtnClick() {
    const { id } = this._state.params;
    const { palette } = this;
    this._state.go('home.tweak', { id, palette });
  }

  async onShareFacebookBtnClick(imageUrl) {
    const url = this._state.href('preview', this._state.params, { absolute: true });
    await this._facebook.share({ url, image: imageUrl });
  }

}

export const NAME = 'PreviewController';
export const NAME_AS = 'preview';
