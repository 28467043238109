import angular from 'angular';
import * as FB from 'fb-sdk-wrapper';
import { once } from 'lodash';

const appId = 416473839091447;

const getFbInstance = once(async () => {
  await FB.load();

  FB.init({
    appId,
    status: true,
    cookie: true,
    version: 'v3.2',
  });

  return FB;
});

class Facebook {
  async share({
    url,
    title = "Pictones",
    description = "Look what I've created with Pictones app",
    image,
    width,
    height,
  }) {
    const fb = await getFbInstance();
    const object = {};
    if (url) object.url = url;
    if (title) object.title = title;
    if (description) object.description = description;
    if (image) object.image = image;
    if (width) object.width = width;
    if (height) object.height = height;

    return new Promise((resolve) => {
      fb.ui({
        method: 'share_open_graph',
        action_type: 'og.shares',
        display: 'popup',
        action_properties: JSON.stringify({ object }),
      }, res => resolve(res));
    });
  }
}


export default angular.module('services.facebook', [])
  .service('facebook', [Facebook])
  .name;
