import './styles/app.scss';
import angular from 'angular';
import angularUIRouter from 'angular-ui-router';
import 'angular-material/angular-material.css';
import angularMaterial from 'angular-material';
import { routing, staticRouting, themeConfig } from './app.config';
import { home, userPalettes, preview } from './features';
import { auth, session, facebook } from './services';

function assignServicesToRootScope($rootScope, $state, $stateParams, auth, session, facebook) {
  $rootScope.$state = $state;
  $rootScope.$stateParams = $stateParams;
  $rootScope.auth = auth;
  $rootScope.session = session;
  $rootScope.facebook = facebook;
}

function bindSideNavMenu($rootScope, $mdSidenav) {
  $rootScope.sideNav = function () {
    $mdSidenav('left').toggle();
  };
}

const app = angular.module('app', [
  angularUIRouter,
  angularMaterial,
  auth,
  session,
  facebook,
  home,
  userPalettes,
  preview,
])
  .config(routing)
  .config(staticRouting)
  .config(themeConfig)
  .run(assignServicesToRootScope)
  .run(bindSideNavMenu);

// Inject dependencies
assignServicesToRootScope.$inject = [
  '$rootScope',
  '$state',
  '$stateParams',
  'auth',
  'session',
  'facebook',
];
