import * as firebase from 'firebase';
import { about } from './templates';

// Firebase init
const config = {
  apiKey: 'AIzaSyAYH-5zSBtn7FJNvVe1HS9CupJHeVXqWEw',
  authDomain: 'pictones.firebaseapp.com',
  databaseURL: 'https://pictones.firebaseio.com',
  storageBucket: 'firebase-pictones.appspot.com',
};
firebase.initializeApp(config);

export function routing($urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true);
  $urlRouterProvider.otherwise('/');
}

export function staticRouting($stateProvider) {
  $stateProvider.state('about', {
    url: '/about',
    template: about,
  });
}

export function themeConfig($mdThemingProvider) {
  $mdThemingProvider.theme('default')
    .primaryPalette('blue-grey')
    .backgroundPalette('grey');
}

routing.$inject = ['$urlRouterProvider', '$locationProvider'];
staticRouting.$inject = ['$stateProvider'];
themeConfig.$inject = ['$mdThemingProvider'];
