/* eslint-disable max-len */
import uuid from 'uuid';

export default class PaletteService {
  constructor({ database, storage }) {
    this._db = database;
    this._storage = storage;
  }

  /**
   * @param String path - path to save under
   * @param files
   * @returns {Promise<any[ url, ref, name ]>}
   * @private
   */
  async _uploadFiles({ path, files }) {
    const promiseLine = files.map(file => new Promise(async (resolve) => {
      const { name } = file;
      const ref = this._storage.child(path);
      const snapshot = await ref.put(file);
      resolve({
        url: snapshot.downloadURL,
        name,
      });
    }));

    return Promise.all(promiseLine);
  }


  /**
   *
   * @param opts
   * @param {Array.<{r: Number, g: Number, b: Number, a: Number}>} opts.colors
   * @param opts.files
   * @returns {Promise<{colors: Array<{r: Number, g: Number, b: Number, a: Number}>, images: any, preview}>}
   */
  async savePublicPalette({ colors, files }) {
    debugger;
    const id = uuid();
    const path = `/public/palette/${id}`;

    const images = await this._uploadFiles({ path, files }); // TODO move to server

    const paletteRef = this._db.ref(path);
    await paletteRef.set({ colors, images });

    // Wait for preview to be generated via cloud func
    const preview = await this._db.onSnapshotReady({ ref: paletteRef.child('preview') });
    debugger;
    return {
      id,
      colors,
      images,
      preview,
    };
  }

  async sharePalette({ colors, files, preview }) {
    const id = uuid();
    const path = `/public/palette/${id}`;

    const images = await this._uploadFiles({ path, files });

    const [uploadedPreview] = await this._uploadFiles({ path, files: [preview] });
    const { url: previewUrl } = uploadedPreview;

    const paletteRef = this._db.ref(path);

    await paletteRef.set({ colors, images, previewUrl });

    return {
      id,
      colors,
      images,
      previewUrl,
    };
  }

  async getPublicPalette(id) {
    const path = `/public/palette/${id}`;
    const res = await this._db.ref(path).once('value');
    return res.val();
  }

  reportPaletteFiles(files) {
    let sessionId = null;

    if (window.ga) {
      window.ga.getAll().forEach((tracker) => {
        sessionId = tracker.get('clientId');
      });

      const id = uuid();

      const path = `/analytics/${sessionId}+${id}`;
      return this._uploadFiles({ path, files });
    }


  }


}
