routes.$inject = ["$stateProvider"];

export default function routes($stateProvider) {
  $stateProvider
    .state("userPalettes", {
      url: "/userPalettes",
      template: require("./userPalettes.html"),
      controller: "UserPalettesController",
      controllerAs: "userPalettes",
      resolve: {
        route($rootScope) {
          return $rootScope.currentNavItem = "userPalettes";
        },
      },
    });
}
