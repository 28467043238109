export default class UserPalettesController {
  constructor($mdDialog, $scope, session, $state) {
    this.mdDialog = $mdDialog;
    this.scope = $scope;
    this.session = session;
    this.state = $state;
    this.loadingPalettes = true;
    this.newName = "";
    this.syntaxValue = "CSS";
    this.numericalSystemValue = "HEX";
    this.loadPalettes();
  }

  loadPalettes() {
    this.session.getPalettes().then((data) => {
      this.palettes = data;
      this.loadingPalettes = false;
    });
  }

  removePalette(paletteToDelete, event) {
    // Check if image is used in other palettes before delete
    const allLinks = this.palettes.map((palette) => {
      if (palette.$id === paletteToDelete.$id) {
        return;
      }
      const usedLinks = palette.usedImages.map(img => img.url);
      return usedLinks;
    });
    const mergedLinks = [].concat.apply([], allLinks);

    paletteToDelete.usedImages.forEach((img) => {
      let usedLink = false;
      mergedLinks.forEach((url) => {
        if (url === img.url) {
          usedLink = true;
        }
      });
      if (!usedLink) {
        this.session.eraseImage(img.refName);
      }
    });
    this.palettes.$remove(paletteToDelete);
    event.stopImmediatePropagation();
  }

  // onEditBtnClick(palette) {
  //   this.state.go("home.tweak", { id: palette.$id, palette });
  // }

  sharePalette(palette, event) {
    event.stopImmediatePropagation();
    const showPopUp = (link) => {
      this.mdDialog.show({
        controller: UserPalettesController,
        template: `
                        <md-dialog-content>
                          <div class="md-dialog-content">
                            <h1 class="md-title">Share your palette with this link:</h1>
                            <p class="text-light">${link}</p>
                            <md-button md-autofocus class="md-primary"
                              ngclipboard
                              ngclipboard-success="userPalettes.cancelDialog()"
                              data-clipboard-text="${link}">Copy link
                            </md-button>
                          </div>
                        </md-dialog-content>
                        `,
        preserveScope: true,
        controllerAs: "userPalettes",
        clickOutsideToClose: true,
      });
    };
    // If was already shared
    if (palette.acessibleLink) {
      showPopUp(palette.acessibleLink);
    }
    // If wasn't shared
    else {
      this.session.makePalettePublic(palette)
        .then((ref) => {
          const link = `https://pictones.firebaseapp.com/tweak/${ref}`;
          this.session.editPalette(palette.$id, { accessibleLink: link })
            .then(showPopUp(link));
        });
    }
  }

  cancelDialog() {
    this.mdDialog.cancel();
  }

  changeName(id, newName) {
    const dbRef = this.palettes[id].$id;
    const update = {
      name: newName || "No name",
    };
    this.session.editPalette(dbRef, update);
  }

  showPaletteData(palette) {
    const number = this.palettes.$indexFor(palette.$id);
    const data = this.palettes.$getRecord(palette.$id).colors;

    this.mdDialog.show({
      controller: UserPalettesController,
      template: `
                <md-dialog-content class="md-dialog-content">
                    <div class="border-bottom md-padding">
                        <md-radio-group ng-model="userPalettes.syntaxValue">
                            <div class="md-horizontal-options" layout="row" layout-align="start center">
                                <p class="md-body-1 text-light">Syntax: </p>
                                <md-radio-button value="CSS" class="md-primary md-body-1 text-light">CSS</md-radio-button>
                                <md-radio-button value="SASS" class="md-primary md-body-1 text-light">SASS</md-radio-button>
                                <md-radio-button value="LESS" class="md-primary md-body-1 text-light">LESS</md-radio-button>
                            </div>
                        </md-radio-group>
                        <md-radio-group ng-model="userPalettes.numericalSystemValue">
                            <div class="md-horizontal-options" layout="row" layout-align="start center">
                                <p class="md-body-1 text-light">Numerical system: </p>
                                <md-radio-button value="RGB" class="md-primary md-body-1 text-light">RGB</md-radio-button>
                                <md-radio-button value="HEX" class="md-primary md-body-1 text-light">HEX</md-radio-button>
                            </div>
                        </md-radio-group>
                    </div>
                    <div class="md-padding">
                        <p ng-repeat="color in userPalettes.palettes[${number}].colors" ng-if="!color.toggled" layout="row" layout-align="space-between center">
                          <span class="md-subhead">
                            <span ng-show="userPalettes.syntaxValue == 'CSS'">.color-{{$index}}
                                <span ng-show="userPalettes.numericalSystemValue == 'RGB'">
                                    { color: rgb({{color.r}},{{color.g}},{{color.b}}); }&nbsp;&nbsp;&nbsp;
                                </span>
                                <span ng-show="userPalettes.numericalSystemValue == 'HEX'">
                                    { color: #{{color | hex }}; }
                                </span>
                            </span>
                            <span ng-show="userPalettes.syntaxValue == 'SASS'">$color-{{$index}}:
                                <span ng-show="userPalettes.numericalSystemValue == 'RGB'">
                                    rgb({{color.r}},{{color.g}},{{color.b}});&nbsp;&nbsp;&nbsp;
                                </span>
                                <span ng-show="userPalettes.numericalSystemValue == 'HEX'">
                                    #{{color | hex }};
                                </span>
                            </span>
                            <span ng-show="userPalettes.syntaxValue == 'LESS'">@color-{{$index}}:
                                <span ng-show="userPalettes.numericalSystemValue == 'RGB'">
                                    rgb({{color.r}},{{color.g}},{{color.b}});&nbsp;&nbsp;&nbsp;
                                </span>
                                <span ng-show="userPalettes.numericalSystemValue == 'HEX'">
                                    #{{color | hex }};
                                </span>
                            </span>
                          </span>
                          <span style="background-color: rgb({{color.r}},{{color.g}},{{color.b}});" class="color-icon-sm border-all"></span>
                        </p>
                    </div>
                </md-dialog-content>
            `,
      parent: angular.element(document.body),
      preserveScope: true,
      controllerAs: "userPalettes",
      clickOutsideToClose: true,
    });
  }
}
