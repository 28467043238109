/* eslint-disable func-names */
import * as firebase from 'firebase';

const storage = firebase.storage().ref();

function Database() {}
Database.prototype = Object.create(firebase.database());

Database.prototype.onSnapshotReady = function ({ ref }) {
  return new Promise(async (resolve) => {
    ref.on('value', (snapshot) => {
      if (snapshot.exists()) {
        ref.off();
        resolve(snapshot.val());
      }
    });
  });
};

const database = new Database();

export {
  storage,
  database,
};