class Analytics {
  constructor() {
    this._ga = window.gtag;
  }

  report(...args) {
    if (this._ga) this._ga(...args);
  }

  event(name, data) {
    const { category, label, value, params, interactive = true } = data;

    const opts = {};
    if (category) opts['event_category'] = category;
    if (label) opts['event_label'] = label;
    if (value) opts['event_value'] = value;
    if (!interactive) opts['non_interaction'] = true;

    if (params) Object
      .entries(params)
      .forEach(([key, value]) => opts[key] = value);

    this.report('event', name, opts);
  }

}

export default new Analytics();