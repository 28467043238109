export default function () {
  return function (rgb) {
    const hex = [rgb.r, rgb.g, rgb.b].map((v) => {
      if (v > 255) {
        v = 255;
      } else if (v < 0) {
        v = 0;
      }
      if (v.toString(16).length == 1) {
        return `0${v.toString(16).toUpperCase()}`;
      }
      return v.toString(16).toUpperCase();
    });
    return hex.join("");
  };
}
