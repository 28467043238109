import angular from "angular";
import firebase from "firebase";

const Auth = firebase.auth();

export class AuthService {
  constructor($mdToast, $mdDialog, session, $state) {
    this.mdToast = $mdToast;
    this.mdDialog = $mdDialog;
    this.session = session;
    this.state = $state;
    this.showRegistration = this.showRegistration.bind(this);
    this.newUser = {
      email: "",
      password: "",
    };
    this.userToLogin = {
      email: "",
      password: "",
    };
    this.registeredToSave = null;
  }

  showRegistration(paletteToSave) {
    this.mdDialog.show({
      template: require("../templates/registration.template.html"),
      parent: angular.element(document.body),
      preserveScope: true,
      clickOutsideToClose: true,
    });
    if (paletteToSave) {
      this.registeredToSave = paletteToSave;
    }
  }

  showLogin(ev) {
    this.mdDialog.show({
      template: require("../templates/login.template.html"),
      parent: angular.element(document.body),
      preserveScope: true,
      targetEvent: ev,
      clickOutsideToClose: true,
    });
  }

  createUser() {
    const e = this.newUser.email;
    const p = this.newUser.password;
    Auth.createUserWithEmailAndPassword(e, p)
      .then((userData) => {
        this.mdDialog.hide();
        this.session.setAuthData(userData);
        this.mdToast.showSimple("Registered and logged!");
        // TODO registered to save
        // if (this.registeredToSave) {
        //     this.session.addPalette(this.registeredToSave[0], this.registeredToSave[1]);
        // }
      })
      .catch((error) => {
        this.mdToast.showSimple(`${error}`);
      });
  }

  logIn() {
    const e = this.userToLogin.email;
    const p = this.userToLogin.password;
    Auth.signInWithEmailAndPassword(e, p)
      .then((authData) => {
        this.session.setAuthData(authData);
        this.mdDialog.hide();
        this.mdToast.showSimple("Logged in!");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === "auth/wrong-password") {
          this.mdToast.showSimple("Wrong password!");
          this.loginFail = true;
        } else if (errorCode === "auth/user-not-found") {
          this.mdToast.showSimple("There is no users with the given email!");
        } else if (errorCode === "auth/network-request-failed") {
          this.mdToast.showSimple("Seems like your network is down!");
        } else {
          this.mdToast.showSimple(errorMessage);
        }
      });
  }

  logoutUser() {
    Auth.signOut().then(() => {
      this.session.destroy();
      this.state.go("home");
      this.mdToast.showSimple("Logged out!");
    }, (error) => {
      this.mdToast.showSimple(error);
    });
  }

  loginGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    Auth.signInWithPopup(provider)
      .then((authData) => {
        this.session.setAuthData(authData.user);
        this.cancelDialog();
        this.mdToast.showSimple("Logged in!");
      })
      .catch((error) => {
        this.mdToast.showSimple(error);
      });
  }

  cancelDialog() {
    this.mdDialog.cancel();
  }

  isLoggedIn() {
    return this.session.getAuthData() !== null;
  }

  resetPassword() {
    Auth.sendPasswordResetEmail(this.userToLogin.email).then(() => {
      this.mdToast.showSimple("Reset link has been sent to your email!");
    }, (error) => {
      this.mdToast.showSimple(error);
    });
  }
}

export default angular.module("services.auth", [])
  .service("auth", ["$mdToast", "$mdDialog", "session", "$state", AuthService])
  .name;
