import angular from "angular";
import angularfire from "angularfire";
import firebase from "firebase";

const database = firebase.database();
const storageRef = firebase.storage().ref();

class SessionService {
  constructor($firebaseArray) {
    this.firebaseArray = $firebaseArray;
    this._authData = JSON.parse(localStorage.getItem("session.authData"));
    // this._cachedPalette = JSON.parse(localStorage.getItem('session.cachedPalette'));
  }

  addPalette(paletteData, imageFiles) {
    return new Promise((resolve) => {
      // Build promise array for checking all images
      const checkDuplicates = imageFiles.map((file, i) => new Promise((resolve) => {
        const imgRef = storageRef.child(`/users/${this._authData.uid}/images/${file.name}`);
        imgRef.getDownloadURL()
        // In case image exists
          .then((url) => {
            console.log(`Duplicate check for ${i} passed with ${url}`);
            const data = {
              url,
              refName: file.name,
              upload: false,
            };
            resolve(data);
          })
        // In case does not exist
          .catch((err) => {
            console.log(`Duplicate check for ${i} passed with no url`);
            const data = {
              file,
              upload: true,
            };
            resolve(data);
          });
      }));
      const checkDuplicatesAll = Promise.all(checkDuplicates);
      // Execute promise array for checking all images
      checkDuplicatesAll.then((results) => {
        const filesToUpload = [];
        results.forEach((result) => {
          if (!result.upload) {
            delete result.upload;
            paletteData.usedImages.push(result);
          } else {
            delete result.upload;
            filesToUpload.push(result.file);
          }
        });
        const uploadTask = filesToUpload.map((file, i) => new Promise((resolve) => {
          const imgRef = storageRef.child(`/users/${this._authData.uid}/images/${file.name}`);
          imgRef.put(file).then((snapshot) => {
            const data = {
              url: snapshot.downloadURL,
              refName: file.name,
            };
            resolve(data);
          });
        }));
        const uploadTaskAll = Promise.all(uploadTask);
        // If upload task is empty add palette data to fbArray
        if (!uploadTask.length) {
          const db = database.ref().child(`/users/${this._authData.uid}`);
          const fbArr = this.firebaseArray(db);
          fbArr.$add(paletteData, firebase.database.ServerValue.TIMESTAMP)
            .then(resolve());
        }
        // If upload task is not empty, execute promise array for all files
        else {
          uploadTaskAll.then((results) => {
            results.forEach((result) => {
              paletteData.usedImages.push(result);
            });
            // Add palette data to fbArray
            const db = database.ref().child(`/users/${this._authData.uid}`);
            const fbArr = this.firebaseArray(db);
            fbArr.$add(paletteData, firebase.database.ServerValue.TIMESTAMP)
              .then(resolve(results.length));
          });
        }
      });
    });
  }

  eraseImage(refName) {
    const ref = firebase.storage().ref().child(`/users/${this._authData.uid}/images/${refName}`);
    ref.delete().then(() => {
      console.log(`${refName} was deleted from storage`);
    }).catch((err) => {
      console.log(`${refName} was not deleted: ${err}`);
    });
  }

  getPalettes() {
    return new Promise((resolve, reject) => {
      if (this._authData !== null) {
        const ref = database.ref(`/users/${this._authData.uid}`);
        resolve(this.firebaseArray(ref));
      } else {
        reject();
      }
    });
  }

  editPalette(sourceID, changes) {
    return new Promise((resolve) => {
      const palettes = database.ref(`/users/${this._authData.uid}/${sourceID}`);
      palettes.update(changes).then(() => {
        resolve();
      });
    });
  }

  makePalettePublic(palette) {
    return new Promise((resolve) => {
      const sharedPalettes = database.ref("/sharedPalettes/");
      const fbArr = this.firebaseArray(sharedPalettes);
      palette.sharedBy = this._authData.uid;
      fbArr.$add(palette).then((ref) => {
        resolve(ref.key);
      });
    });
  }

  findPalette(id) {
    return new Promise((resolve) => {
      const result = database.ref(`/public/palette/${id}`);
      result.once("value").then((snapshot) => {
        resolve(snapshot.val());
      });
    });
  }

  getAuthData() {
    return this._authData;
  }

  getGoogleAccessToken() {
    if (this._authData && this._authData.google && this._authData.google.accessToken) {
      return this._authData.google.accessToken;
    }
    return null;
  }

  setAuthData(authData) {
    this._authData = authData;
    localStorage.setItem("session.authData", JSON.stringify(authData));
  }

  // Used for browser refreshes and transitions
  cachePalette(data) {
    this._cachedPalette = data;
    localStorage.setItem("session.cachedPalette", JSON.stringify(data));
  }

  getCachedPalette() {
    return new Promise((resolve, reject) => {
      if (this._cachedPalette !== null) {
        resolve(this.cachedPalette);
      } else {
        reject();
      }
    });
  }

  destroy() {
    this.setAuthData(null);
  }
}

export default angular.module("services.session", [angularfire])
  .service("session", ["$firebaseArray", SessionService])
  .name;
