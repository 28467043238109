import angular from 'angular';
import uirouter from 'angular-ui-router';
import ngFileUpload from 'ng-file-upload';
import ngClipboard from 'ngclipboard';

import routing from './home.routes';
import HomeController from './HomeController';
import HexFilter from '../../filters/hexFilter.filter.js';


export default angular.module('app.home', [uirouter, ngFileUpload, ngClipboard])
  .config(routing)
  .controller('HomeController', [
    '$mdToast',
    'session',
    'auth',
    'Upload',
    'homeState',
    '$scope',
    '$state',
    HomeController,
  ])
  .filter('hex', HexFilter)
  .name;
