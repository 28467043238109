import { first } from 'lodash';
import { toJpeg, toBlob } from 'html-to-image';
import { saveAs } from 'file-saver';
import uuid from 'uuid';
import quantizer from './quantizer';
import paletteService from '../../services/palette';
import analytics from '../../services/analytics';
import isSafari from 'is-safari';

const defaultOpts = {
  paletteName: 'No name',
  syntaxValue: 'CSS',
  numericalSystemValue: 'RGB',
  isChangingColor: false,
  isSaving: false,
  isGenerating: false,
  isDownloading: false,
  colorAmount: 6,
  colors: [],
  currentColorIndex: null,
};

export default class HomeController {
  constructor($mdToast, session, auth, Upload, homeState, $scope, $state) {
    this.scope = $scope;
    this.state = $state;
    this.session = session;
    this.mdToast = $mdToast;
    this.auth = auth;
    this.upload = Upload;
    this.upload.defaults.blobUrlsMaxMemory = 268435456;
    this.paletteToTweak = homeState;
    // State
    Object.assign(this, defaultOpts);
    // Init
    this.onMount();
    window.home = this;
  }

  savePaletteChanges() {
    const source = this.paletteToTweak.$id;
    const data = {
      name: this.paletteName,
      colors: this.colors,
    };
    this.session.editPalette(source, data);
    this.mdToast.show(
      this.mdToast.simple()
        .textContent('Palette tweaks saved!')
        .position('bottom left')
        .hideDelay(3000),
    );
  }

  _resetState() {
    this.paletteToTweak = null;
    this.palette = {};
  }

  async _generatePalette(image) {
    const colors = await quantizer.getColors({
      colors: this.colorAmount,
      image,
    });
    return { colors, image };
  }

  async _resizeImages(files) {
    const opts = { width: 600, quality: 0.8, type: 'image/jpeg' };
    const chain = files.map(file => this.upload.resize(file, opts));
    return Promise.all(chain);
  }

  async onMount() {
    const { id, palette } = this.state.params;
    if (!id) { return; }

    // In case palette is private read from passed data
    // In case palette is publicly shared read from url id
    this.palette = palette || await this.session.findPalette(id);
  }

  async reportFiles(files) {
    const resized = await this._resizeImages(files);
    await paletteService.reportPaletteFiles(resized);
  }

  async onFileUpload(files) {
    analytics.event('click', {
      category: 'button',
      label: 'upload',
    });

    if (!files || !files.length) return;
    this.reportFiles(files);

    this._resetState();
    this.files = files; // Bind files to scope to show in template before cpu intensive task

    this.imageDimensions = await this.upload.imageDimensions(first(this.files));


    const { height, width } = this.imageDimensions;
    analytics.event('upload', {
      params: { height, width },
      interactive: false,
    });

    this.isGenerating = true;
    this.palette = await this._generatePalette(this.files);
    this.isGenerating = false;
  }

  async onInspireBtnClick() {
    analytics.event('click', {
      category: 'button',
      label: 'inspire',
    });

    this._resetState();

    this.isGenerating = true;

    const url = `https://source.unsplash.com/random/${new Date().getTime()}`;
    const blob = await this.upload.urlToBlob(url);

    this.files = await this._resizeImages([blob]);
    this.imageDimensions = await this.upload.imageDimensions(first(this.files));

    this.palette = await this._generatePalette(this.files);
    this.isGenerating = false;
  }

  async onColorAmountChange() {
    if (!this.files) { return; }

    analytics.event('click', {
      category: 'slider',
      label: 'color_quantity',
      params: {
        amount: this.colorAmount,
      },
    });

    this.isGenerating = true;
    this.palette = await this._generatePalette(this.files);
    this.isGenerating = false;
  }

  onColorBtnClick(index) {

    analytics.event('click', {
      category: 'button',
      label: 'color_button',
      params: {
        index: index,
      },
    });

    const { toggled } = this.colors[index];
    const isSelected = index === this.currentColorIndex;

    // Select toggled color or exclude non current color
    if (!isSelected && toggled) return;

    // Select not current color or include any other color
    if (!isSelected && !toggled) this.currentColorIndex = index;
    // Select or exclude current color
    else this.currentColorIndex = null;
  }

  onColorCopyBtnClick($event) {
    $event.stopPropagation();

    analytics.event('click', {
      category: 'button',
      label: 'color_copy',
    });

    this.mdToast.showSimple('Copied to clipboard!');
  }

  onToggleBtnClick(index, $event) {
    $event.stopPropagation();

    analytics.event('click', {
      category: 'button',
      label: 'color_toggle',
    });

    const color = this.colors[index];
    color.toggled = !color.toggled;
    this.onColorBtnClick(index);
  }

  async onSavePaletteBtnClick() {
    if (!this.files) { return; }

    const { palette, files } = this;
    const isLogged = this.auth.isLoggedIn();
    this.isSaving = true;

    const resized = await this._resizeImages(files);
    if (isLogged) {
      const res = await this.session.addPalette(palette, resized);
      this.isSaving = false;
      const text = (res > 1) ? `Palette was saved! ${res} images are uploaded!` : 'Palette was saved';
      const mdRes = await this.mdToast.show(this.mdToast.simple()
        .textContent(text)
        .action('Saved tones')
        .highlightAction(true)
        .position('bottom left')
        .hideDelay(6000));
      if (mdRes === 'ok') this.state.go('userPalettes');
    } else {
      const data = [];
      data.push(palette, resized);
      this.auth.showRegistration(data);
      this.isSaving = false;
    }
  }

  generatePreviewImage({ src, colors, blob }) {
    return new Promise(async resolve => {
      const previewWidthMaxPx = 640;

      const isImageReady = path => new Promise(resolve => {
        const img = new Image();
        img.onload = () => resolve({ path, status: 'ok' });
        img.onerror = () => resolve({ path, status: 'error' });

        img.src = path;
      });

      const wrapper = document.createElement('div');
      wrapper.style = `
        max-width: ${previewWidthMaxPx}px;
        position: relative;
        display: flex;
      `;

      const palette = document.createElement('div');
      palette.style = `
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        position: absolute;
        bottom: 0;
      `;

      const watermark = document.createElement('div');
      watermark.style = `
        position: absolute;
        width: 160px;
        top: 10px;
        left: 10px;
        z-index: 999;
        background-color: rgba(0,0,0,0.3);
        padding: 5px 15px 0;
      `;

      const waterMarkImg = document.createElement('img');
      const waterMarkSrc = '/logo.png';
      waterMarkImg.src = waterMarkSrc;

      waterMarkImg.style = `
        width: 108px;
        height: auto;
      `;

      const waterMarkText = document.createElement('span');
      waterMarkText.innerText = 'Created with';
      waterMarkText.style = `
        color: white;
        font-size: 18px;
        font-family: Arial;
        font-weight: bold;
      `;

      const domain = document.createElement('span');
      domain.innerText = '.design';
      domain.style = `
         color: #ff5252;
         display: inline-block;
         padding-top: 6px;
         vertical-align: top;
      `;

      let padding = 15;
      if (colors.length > 12) padding = 10;
      if (colors.length > 22) padding = 8;

      const colorWrapperElStyles = `
        display: flex;
        align-items: center;
        display: block;
        padding: ${padding}px;
        background-color: #fafafa;
        // border-top: dashed 1px #E7EBF3;
        border-left: dashed 1px #E7EBF3;
      `;

      let size = 50;
      if (colors.length > 12) size = 36;
      if (colors.length > 22) size = 28;

      const colorsEls = colors.map(({ r, g, b }) => {
        const el = document.createElement('div');
        el.style = `
          width: ${size}px;
          height: ${size}px;
          border-radius: 50%;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
          background-color: rgb(${r}, ${g}, ${b});
        `;
        return el;
      });

      colorsEls.forEach((colorEl, i) => {
        const el = document.createElement('div');
        el.style = colorWrapperElStyles;
        if (i === 0) el.style.borderLeft = 'none';
        el.appendChild(colorEl);
        palette.appendChild(el);
      });

      const img = document.createElement('img');
      img.src = src;
      img.style = `
        width: 100%;
        height: auto;
      `;

      watermark.appendChild(waterMarkText);
      watermark.appendChild(waterMarkImg);
      watermark.appendChild(domain);
      wrapper.appendChild(watermark);
      wrapper.appendChild(img);
      wrapper.appendChild(palette);

      const previewContainerClassName = 'js-preview-image-container';
      let previewEl = document.querySelector(`.${previewContainerClassName}`);
      if (!previewEl) {
        previewEl = document.createElement('div');
        previewEl.classList.add(previewContainerClassName);
        previewEl.style = `
          position: absolute;
          top: 0;
          left: 0;
          transform: translateX(-100%);
        `;
        document.body.appendChild(previewEl);
      }

      previewEl.appendChild(wrapper);

      await Promise.all([src, waterMarkSrc].map(isImageReady));

      if (blob) {
        const blob = toBlob(wrapper);
        resolve(blob);
      }
      const jpeg = await toJpeg(wrapper);
      resolve(jpeg);
    });
  }

  onCurrentColorSliderChange() {
    analytics.event('click', {
      category: 'slider',
      label: 'current_color',
    });
  }

  async onDownloadBtnClick({ colors, files }) {

    analytics.event('click', {
      category: 'button',
      label: 'download_palette',
      params: {
        'colors_amount': colors.length,
      },
    });

    this.isDownloading = true;

    const resized = await this._resizeImages(files);

    const image = await this.generatePreviewImage({ src: URL.createObjectURL(first(resized)), colors });

    this.isDownloading = false;

    saveAs(image, uuid());
  }

  async onShareBtnClick({ colors, files }) {
    this.isSharing = true;

    const resized = await this._resizeImages(files);
    const preview = await this.generatePreviewImage({
      src: URL.createObjectURL(first(resized)),
      colors,
      blob: true,
    });

    const palette = await paletteService.sharePalette({ colors, files: resized, preview });

    this.state.go('preview', { id: palette.id, palette });
  }

  get palette() {
    return {
      colors: this.colors,
      usedImages: [],
    };
  }

  set palette({ colors, usedImages, images }) {

    if (colors) this.colors = colors;

    debugger;
    let imgs = usedImages || images;
    if (imgs) {
      let { url } = first(imgs);
      this.files = [url]; // update preview
    }

    if (colors || usedImages) this.scope.$apply();

  }

  get files() {
    return this._files;
  }

  set files(value) {
    this._files = value;
  }

  get isInEditMode() {
    return !this.files || this.paletteToTweak;
  }

  get isSafari() {
    return isSafari;
  }

  get isOldSafari() {
    // At least Safari 3+: "[object HTMLElementConstructor]"
    return Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
  }

  get isImagePortrait() {
    const { height, width } = this.imageDimensions || {};
    return height >= width;
  }

  get currentColor() {
    return this.colors[this.currentColorIndex];
  }

}
