export default function routes($stateProvider) {
  $stateProvider
    .state("home", {
      url: "/",
      template: require("./home.html"),
      controller: "HomeController",
      controllerAs: "home",
      resolve: {
        homeState($stateParams, $rootScope) {
          $rootScope.currentNavItem = "home";
          return $stateParams.palette;
        },
      },
      params: {
        palette: null,
        id: null,
      },
    })
    // .state("home.tweak", {
    //   url: "tweak/{id}",
    //   resolve: {
    //     homeState($rootScope) {
    //       $rootScope.currentNavItem = "tweak";
    //     },
    //   },
    // });
}

routes.$inject = ["$stateProvider"];
