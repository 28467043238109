import RgbQuant from 'rgbquant';
import { first } from 'lodash';
import isUrl from 'is-url';
import isBase64 from 'is-base64';

class Quantizer {

  _buildCanvas({ file, url }) {
    return new Promise((resolve) => {
      const src = url || URL.createObjectURL(file);

      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = src;
      img.onload = async () => {

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.canvas.width = img.width;
        ctx.canvas.height = img.height;
        ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height);

        resolve(canvas);
      };
    });
  }

  _getColors({ colors, canvas }) {
    // CPU intensive task wrapped in promise for non blocking / performance reasons
    return new Promise(resolve => {
      performance.mark('_getColors:start');

      const opts = {
        colors,
        method: 1,
        useCache: true,
        colorDist: 'euclidean',
      };
      const q = new RgbQuant(opts);
      q.sample(canvas);
      const pal = q.palette(true);

      const mapped = pal.map((color) => {
        const [r, g, b] = color; // Destruct all color values
        return { r, g, b }; // Transform colors to objects to semantically use values in templates
      });
      performance.mark('_getColors:end');
      performance.measure('_getColors', '_getColors:start', '_getColors:end');
      const measures = performance.getEntriesByName('_getColors');
      const measure = measures[0];
      console.log('generate colors:', measure.duration);
      resolve(mapped);
    });
  }

  async getColors({ image, colors }) {
    if (!image) return;
    if (Array.isArray(image)) image = first(image);

    const valid = isUrl(image) || isBase64(image) || image instanceof Blob || image instanceof File;
    if (!valid) throw 'Argument should be either file, blob, valid url or base64 string';

    const opts = {};
    if (isUrl(image)) opts.url = image;
    else opts.file = image;

    const canvas = await this._buildCanvas(opts);
    return this._getColors({ colors, canvas });
  };

}

export default new Quantizer();



